<template>
<div>
  <b-row>
    <b-col>
      <b-card>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Molfix Direct</h5>
        </b-card-body>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.molfix"
        class="contact-table"
        >
          <template #cell(head_title)="data">
            <span>
              {{ formatTitle(data.item.head_title) }}
            </span>
          </template>
          <template #cell(balance)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.balance) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="mt-4">
    <b-col>
      <b-card>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">Circles Data</h5>
        </b-card-body>
        <b-table
        hover
        bordered
        responsive
        :items="loadedData.circles"
        class="contact-table"
        >
          <template #cell(lockCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.lockCircle) }}
            </span>
          </template>
          <template #cell(currentCircle)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.currentCircle) }}
            </span>
          </template>
          <template #cell(mdTarnol)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.mdTarnol) }}
            </span>
          </template>
          <template #cell(directInvenstment)="data">
            <span>
              {{ Intl.NumberFormat().format(data.item.directInvenstment) }}
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</div>  
</template>

<script>
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import NProgress from "nprogress";
export default {
  name: "molfix-dashboard",
  data: () => ({
    itemsMolfix: [],
    itemsCircle: [],
    dashboardData: {
      molfixTotal: 0,
      mdtarnolMolfix: 0
    },
    molfix:[7,51,48,1,31,35,11,17,30,10,28,37,38,39,40,45,47,49],
    cutOfDate: null,
    dates: {
      start: null,
      end: null,
      codate: null
    }
  }),
  computed: {
      ...mapState({
        codate: state => state.dashboard.codate,
        molfixCircle: state => state.dashboard.molfix,
        loadedData: state => state.dashboard.loadedData,
        refreshState: state => state.dashboard.refreshState,
        userRole: state => state.auth.authData
      }),
  },
  watch: {
    refreshState(val){
      if(val){
        this.itemsMolfix = [];
        this.itemsCircle = [];
        this.initDashboard();
      }
    }
  },
  async mounted(){
    if(!this.userRole){
      if(localStorage.getItem('token')){
        let roles = localStorage.getItem('access').split(',');
        roles = roles.map(r => {
          return parseInt(r);
        });
        this.saveAuthData({username: localStorage.getItem('user'), access: roles});
      }
      else{
        this.saveAuthData(null);
        this.$router.push({path: "/dashboard/molfix-dashboard"});
      }
    }
    if(!this.loadedData){
      await this.initDashboard();
    }
  },
  methods:{
    ...mapActions(["reloadData", "saveLoadedData", "saveAuthData", "getCircles", "getFauji", "getLu", "getMolfix", "getTapal", "getStockValue", "getPendingOrders", "getSupplierBalance", "getDiscounts", "getAutoDiscounts", "getShopsCredit", "getSales", "getPurchase", "getSalesmanCredit", "getLedger"]),
    formatTitle(text){
      if(text && text.includes(":")){
        return text.substr(0,15);
      }
      else{
        return text || "NA";
      }
    },
    getFirstDayOfMonth(dt){
      return moment(dt).startOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    getLastDayOfMonth(dt){
      return moment(dt).endOf("month").format('YYYY-MM-DD HH:mm:ss');
    },
    async initDates(){
      return new Promise((resolve) => {
        NProgress.start();
        this.getCircles().then(() => {
          this.dates.start = this.getFirstDayOfMonth(this.codate);
          this.dates.end = this.getLastDayOfMonth(this.codate);
          this.dates.codate = this.codate;
          resolve();
        })
      });
    },
    async initLedgers(){
      return new Promise((resolve) => {
        this.getLedger({id:3,ledger:8,dates:this.dates}).then(n => {
          this.dashboardData.mdtarnolMolfix = n[0].debit - n[0].credit;
          resolve();
        })
      })
    },
    initCircles(){
      this.getCircles().then(() => {
        this.itemsCircle.push({
          business: "Molfix Direct",
          lockCircle: this.molfixCircle,
          currentCircle: this.dashboardData.molfixTotal,
          mdTarnol: this.dashboardData.mdtarnolMolfix,
          directInvenstment: this.dashboardData.molfixTotal + this.dashboardData.mdtarnolMolfix 
        });
        this.reloadData(false);
        NProgress.done();
      })
    },
    async prepareMolfix(){
      return new Promise((resolve) => {
        this.getMolfix({ids: this.molfix,dates:this.dates}).then(async fdata => {
          await this.timeOut(1000);
          this.getStockValue({id:3,dates:this.dates}).then(async stock => {
            this.itemsMolfix.push({"head_title": "Stock Value", "balance":stock[0].stock});
            await this.timeOut(1000);
            this.getPendingOrders({id:3,dates:this.dates}).then(async p => {
              this.itemsMolfix.push({"head_title": "Pending Orders", "balance":p[0].balance});
              await this.timeOut(1000);
              this.getSupplierBalance({id:3,dates:this.dates}).then(async sup => {
                await this.timeOut(1000);
                sup.forEach(async (s,n) => {
                  await this.timeOut(1000);
                  this.getLedger({id:3,ledger:s.supplier_id,dates:this.dates}).then(l => {
                    this.itemsMolfix.push({"head_title": s.supplier_name, "balance":l[0].debit - l[0].credit - s.purchase});
                  })
                  if(sup.length === n+1){
                    await this.timeOut(1000);
                    this.getShopsCredit({id:3,dates:this.dates}).then(async shop => {
                      this.itemsMolfix.push({"head_title": "Shops Credit", "balance":shop[0].shopcredit});
                      await this.timeOut(1000);
                      this.getSales({id:3,dates:this.dates}).then(async sale => {
                        const allsales = sale;
                        await this.timeOut(1000);
                        this.getPurchase({id:3,dates:this.dates}).then(async purchase => {
                        await this.timeOut(1000);
                        this.getSalesmanCredit({id:3,dates:this.dates}).then(async credit => {
                          this.itemsMolfix.push({"head_title": "Salesman Credit", "balance":(allsales[0].sales + credit[0].debit) - credit[0].credit});
                          await this.timeOut(1000);
                          this.getDiscounts({id:3,dates:this.dates}).then(async disc => {
                            await this.timeOut(1000);
                            this.getAutoDiscounts({id:3, type: "sale",ledger: [3,4,5], dates:this.dates}).then(async autodisc => {
                              await this.timeOut(1000);
                              this.getLedger({id:3, type:"discounts", ledger:[3,4,5,12,14], dates:this.dates}).then(async dl => {
                                this.itemsMolfix.push({"head_title": "Discounts", "balance":(disc[0].discounts + autodisc[0].discounts + dl[0].debit) - dl[0].credit});
                                await this.timeOut(1000);
                                this.getAutoDiscounts({id:3, type: "purchase",ledger: [6], dates:this.dates}).then(async autodiscp => {
                                  await this.timeOut(1000);
                                  this.getLedger({id:3, type:"discounts", ledger: 6, dates:this.dates}).then(async dlp => {
                                    this.itemsMolfix.push({"head_title": "Discount on purchase", "balance":dlp[0].debit - (autodiscp[0].discounts + dlp[0].credit)});
                                    await this.timeOut(1000);
                                    this.getLedger({id:3,ledger:15,dates:this.dates}).then(async st => {
                                      this.itemsMolfix.push({"head_title": st[0].head_title, "balance": (st[0].debit + purchase[0].salestax) - (allsales[0].stax + st[0].credit)});
                                      await this.timeOut(1000);
                                      this.getLedger({id:3,ledger:16,dates:this.dates}).then(ft => {
                                        this.itemsMolfix.push({"head_title": ft[0].head_title, "balance":  ft[0].debit - (allsales[0].ftax + ft[0].credit)});
                                        fdata.forEach((el, n) => {
                                          this.itemsMolfix.push(el);
                                          if(fdata.length === n+1){
                                            this.dashboardData.molfixTotal = _.sumBy(this.itemsMolfix, function(o) { return o.balance; });
                                            this.itemsMolfix.push({"head_title": "Total", "balance": this.dashboardData.molfixTotal});
                                            this.itemsMolfix.push({"head_title": "Circle", "balance": this.molfixCircle});
                                            this.itemsMolfix.push({"head_title": "Diff", "balance": this.dashboardData.molfixTotal - this.molfixCircle});
                                            resolve();
                                          }
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                        });
                      })
                    });
                  }
                })
              });
            });
          });
        });
      })
    },
    async timeOut(interval){
      return new Promise((resolve) => {
        setTimeout(() => {  resolve(); }, interval);
      })
    },
    async initDashboard(){
      this.initDates();
      await this.timeOut(1000);
      await this.initLedgers();
      await this.timeOut(1000);
      await this.prepareMolfix();
      await this.timeOut(1000);
      this.initCircles();
    }
  }
};
</script>
